import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import Button from '../common/components/Button';
import { connect } from 'react-redux';
import { getPromoCode } from '../payment/payment-actions';
import ValidationError from '../common/forms/ValidationError';
import InputScaffold from '../common/forms/InputScaffold';

const strings = new LocalizedStrings({
  en: {
    promoCode: 'Promo Code',
    invalidPromoCode: 'Invalid Promo Code',
    apply: 'Apply',
  },
});

class PromoCodeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promoCode: props.promoCode ? props.promoCode.get('code') : '',
    };
  }

  renderError() {
    const { promoCodeError, promoCode } = this.props;

    if (promoCodeError || (promoCode && !promoCode.get('valid'))) {
      return <ValidationError>{strings.invalidPromoCode}</ValidationError>;
    }

    return null;
  }

  render() {
    const { dispatch, applyingPromoCode, email, fittingType } = this.props;

    return (
      <form
        onSubmit={event => {
          event.stopPropagation();
          event.preventDefault();
          dispatch(getPromoCode({code: this.state.promoCode, email, fittingType}))
            .then((action) => {
              if (action.json && action.json.valid) {
                this.props.onPromoCodeSubmit(this.state.promoCode, action.json.order.discount_amount);
                this.setState({ promoCode: '' }); // Consider moving or removing this line based on desired UX
              }
              else{
                console.log('Invalid found in PromoForm');
              }
            })
            .catch((error) => {
              console.error('Error applying promo code', error);
            });
        }}
      >
        <div className="promo-code--form">
          <InputScaffold label={strings.promoCode}>
            <input
              type="text"
              value={this.state.promoCode}
              onChange={event => this.setState({ promoCode: event.target.value })}
            />
          </InputScaffold>
          {this.renderError()}
          <Button disabled={applyingPromoCode} type="submit">
            {strings.apply}
          </Button>
        </div>
      </form>
    );
  }
}

export default connect(state => ({
  applyingPromoCode: state.payment.getPromoCode.get('loading'),
  promoCodeError: state.payment.getPromoCode.get('error'),
  promoCode: state.payment.promoCode,
}))(PromoCodeForm);
