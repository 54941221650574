import { combineReducers } from 'redux';
import {
  INVITE_FITTER,
  FETCH_FITTERS,
  DELETE_FITTER,
  UPDATE_FITTER,
  UPDATE_USER_PROFILE,
  FETCH_MY_RESULTS_LIST,
  CLEAR_FITTER_CACHE,
  GET_MY_FITTING_CODES_LIST,
  SHARE_VOUCHER,
  GET_SUBSCRIPTIONS_LIST
} from './user-action-constants';
import Immutable, { List } from 'immutable';
import getApiReducer from '../common/utils/getApiReducer';

let rollbacks = {};

const initialFittersState = {
  results: List(),
  total: 0,
  filtersList: List(),
};

const updateFiltersList = (newAccount, stateFiltersList) => {
  let newFiltersList = stateFiltersList.toArray();

  if (newAccount && !newFiltersList.includes(newAccount.toUpperCase())) {
    newFiltersList.push(newAccount.toUpperCase());
  } else if (!newAccount && !newFiltersList.includes(null)) {
    newFiltersList.push(null);
  }
  return newFiltersList;
};

const initialClearCacheState = {
  loading: false,
  success: false,
  error: null,
};

export default combineReducers({
  deleteFitter: getApiReducer(DELETE_FITTER),
  fetchFitters: getApiReducer(FETCH_FITTERS),
  inviteFitter: getApiReducer(INVITE_FITTER),
  updateFitter: getApiReducer(UPDATE_FITTER),
  updateUserProfile: getApiReducer(UPDATE_USER_PROFILE),
  clearFitterCache: getApiReducer(CLEAR_FITTER_CACHE),
  fitters: (state = initialFittersState, action) => {
    if (action.type === `${FETCH_FITTERS}_SUCCESS`) {
      return {
        ...state,
        results: Immutable.fromJS(action.json).get('paginatedResults'),
        total: Immutable.fromJS(action.json).get('total'),
        filtersList: Immutable.fromJS(action.json).get('filtersList'),
      };
    }
    if (state && action.type === `${UPDATE_FITTER}_REQUEST`) {
      const newResults = state.results.map(fitter => {
        if (fitter.get('id') === action.id) {
          rollbacks[action.id] = fitter;
          return fitter.merge(action.values);
        }
        return fitter;
      });

      const newFiltersList = updateFiltersList(action.values.account, state.filtersList);

      return {
        ...state,
        results: newResults,
        filtersList: List(newFiltersList),
      };
    }
    if (state && action.type === `${UPDATE_FITTER}_FAILURE`) {
      const newResults = state.results.map(fitter => {
        if (rollbacks[action.id] && fitter.get('id') === action.id) {
          return rollbacks[action.id];
        }
        return fitter;
      });

      return {
        ...state,
        results: newResults,
      };
    }
    return state;
  },
  fetchMyResultsList: getApiReducer(FETCH_MY_RESULTS_LIST),
  myResultsList: (state = List(), action) => {
    if (action.type === `${FETCH_MY_RESULTS_LIST}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  getMyFittingCodesList: getApiReducer(GET_MY_FITTING_CODES_LIST),
  myFittingCodesList: (state = List(), action) => {
    if (action.type === `${GET_MY_FITTING_CODES_LIST}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  getSubscriptionsList: getApiReducer(GET_SUBSCRIPTIONS_LIST),
  mySubscriptionsList: (state = List(), action) => {
    if (action.type === `${GET_SUBSCRIPTIONS_LIST}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  shareVoucher: getApiReducer(SHARE_VOUCHER),
  clearCache: (state = initialClearCacheState, action) => {
    if (action.type === `${CLEAR_FITTER_CACHE}_REQUEST`) {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }
    if (action.type === `${CLEAR_FITTER_CACHE}_SUCCESS`) {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    if (action.type === `${CLEAR_FITTER_CACHE}_FAILURE`) {
      return {
        ...state,
        loading: false,
        error: action.json,
      };
    }
    return state;
  },
});
