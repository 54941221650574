import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import FieldWithLabelAndValidation from '../../../common/forms/FieldWithLabelAndValidation';
import LocalizedStrings from 'react-localization';
import { updateUserProfile } from '../../user-actions';
import compose from 'lodash/flowRight';
import { getEmailSettingsUrl, getChangePasswordUrl } from '../../../common/utils/oidc-url-builders';
import BillingHistory from './BillingHistory';
import { toast } from 'react-toastify';
import FitterResultsEmailForm from './FitterResultsEmailForm';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    myProfile: 'My Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Management',
    saveProfile: 'Save Profile',
    passwordManagement: 'Password Management',
    changePassword: 'Change Password',
    changeEmail: 'Change Email',
    profileUpdated: 'Profile Updated',
    logout: 'Logout',
    billingHistory: 'Billing History',
  },
});

class MyProfile extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.userProfileUpdated && this.props.userProfileUpdated) {
      toast(strings.profileUpdated, { type: 'success' });
    }

    if (window.location.hash && window.location.hash !== prevProps.location.hash) {
      const element = document.querySelector(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  componentDidMount() {
    if (window.location.hash) {
      const element = document.querySelector(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  renderEmailStatusTag() {
    const { loggedInUser } = this.props;

    return (
      <div
        className={`email-status-tag ${loggedInUser.getIn(['oidcData', 'profile', 'email_verified']) && 'verified'}`}
      >
        {loggedInUser.getIn(['oidcData', 'profile', 'email_verified']) ? 'Verified' : 'Unverified'}
      </div>
    );
  }

  renderEmailSettings() {
    const { loggedInUser } = this.props;
    return (
      <div className="email-settings">
        <h3>{strings.email}</h3>
        <div className="current-email__wrapper">
          <span className="current-email">{loggedInUser.getIn(['oidcData', 'email'])}</span>
          {this.renderEmailStatusTag()}
        </div>
        <a className="button button--color--primary" href={getEmailSettingsUrl()}>
          {strings.changeEmail}
        </a>
      </div>
    );
  }

  renderPasswordManagement() {
    return (
      <div className="password-management">
        <h3>{strings.passwordManagement}</h3>
        <a className="button button--color--primary" href={getChangePasswordUrl()}>
          {strings.changePassword}
        </a>
      </div>
    );
  }

  render() {
    const { loggedInUser } = this.props;

    return (
      <div className="page--my-profile">
        <Helmet>
          <title>{`${strings.myProfile} - ${strings.title}`}</title>
        </Helmet>
        <div className="container small">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
            <h1>{strings.myProfile}</h1>
            <Link to="/logout" style={{ paddingTop: '13px', height: '0px' }}>
              {strings.logout}
            </Link>
          </div>
          <Form className="profile--name-form">
            <FieldWithLabelAndValidation
              label={strings.firstName}
              fieldProps={{
                name: 'first_name',
                type: 'text',
              }}
            />
            <FieldWithLabelAndValidation
              label={strings.lastName}
              fieldProps={{
                name: 'last_name',
                type: 'text',
              }}
            />
            <div className="button__wrapper">
              <button className="button button--color--primary" type="submit">
                {strings.saveProfile}
              </button>
            </div>
          </Form>
          <hr />
          <FitterResultsEmailForm />
          {this.renderEmailSettings()}
          <hr />
          {this.renderPasswordManagement()}
          <hr />
          {loggedInUser.get('type') === 'player' ? (
            <div id="billing">
              <BillingHistory />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    userProfileUpdated: state.user.updateUserProfile.get('loaded'),
  })),
  withFormik({
    mapPropsToValues: props => ({
      first_name: props.loggedInUser.get('first_name') || '',
      last_name: props.loggedInUser.get('last_name') || '',
      fitter_results_email: props.loggedInUser.get('fitter_results_email'),
    }),
    handleSubmit(values, { props }) {
      return props.dispatch(updateUserProfile(values));
    },
  })
)(MyProfile);
