import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFAQs } from '../lib/contentful/contentful-actions';
import LoadingIndicator from '../common/components/LoadingIndicator';
import FAQItem from './FAQItem';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';
import Footer from '../common/components/Footer';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'FAQ',
    header: 'Ballnamic FAQ',
    headerHelperText: 'Here are our most asked questions.',
  },
});

function FAQ({ faqs, loaded, dispatch }) {
  useEffect(() => {
    dispatch(getFAQs());
  }, [dispatch]);
  return (
    <>
      <div className="container landing-page">
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>
        <div className="faq-header">
          <h1>{strings.header}</h1>
          <h3>{strings.headerHelperText}</h3>
        </div>
        {!loaded ? (
          <LoadingIndicator />
        ) : (
          <div className="faq-items--list">
            {faqs.get('items').size ? (
              faqs.get('items').map(item => {
                return (
                  <FAQItem
                    key={item.getIn(['sys', 'id'])}
                    title={item.getIn(['fields', 'title'])}
                    body={item.getIn(['fields', 'body'])}
                  />
                );
              })
            ) : (
              <div className="faq--zero-content-placeholder">No results found.</div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default connect(state => ({
  loaded: state.contentful.getFAQs.get('loaded'),
  faqs: state.contentful.FAQs,
}))(FAQ);
