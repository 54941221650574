import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ScrollToTop from '../common/components/ScrollToTop';
import LocalizedStrings from 'react-localization';
import LandingPageHeader from '../landing-page/LandingPageHeader';
import { fetchLoggedInUser } from '../auth/auth-actions';
import { validateVoucherCode } from '../payment/payment-actions';
import LoadingIndicator from '../common/components/LoadingIndicator';

import PurchaseForm from './PurchaseForm';
import { Link, useHistory } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    voucherHeading: 'Enter Your Fitting Code to Get Started',
    fittingCode: 'FITTING CODE',
    voucherText1:
      'Don’t have a code? Purchase one ',
    voucherText2: 'here',
    voucherText3:
      ' to unlock your fitting.',
    startMyFitting: 'Start My Fitting',
    blankFittingCode: 'Please insert a valid fitting code',
    invalidFittingCode: 'This fitting code is invalid or expired.',
    insertFittingCode: "Enter your fitting code",
  },
});

const Purchase = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.auth.loggedInUser);
  const token = useSelector(state => state.auth.token);
  const fetchingLoggedInUser = useSelector(state => state.auth.fetchLoggedInUser.get('loading'));

  const [isModalOpen, setIsModalOpen] = useState((localStorage.getItem('voucher') || localStorage.getItem('purchasedWithoutAccount'))? false : true);
  const [fittingCode, setFittingCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    window.analytics.page();
    if (token) {
      dispatch(fetchLoggedInUser());
    }
  }, [dispatch, token]);

  useEffect(() => {
    const handleValidation = async () => {
      if (localStorage.getItem('action') === 'redeemVoucher' && localStorage.getItem('voucher')) {
        setFittingCode(localStorage.getItem('voucher'));
        await validateVoucher(localStorage.getItem('voucher'));
      }

      if (localStorage.getItem('purchasedWithoutAccount')) {
        localStorage.removeItem('purchasedWithoutAccount');
      }
    };

    handleValidation();
  // eslint-disable-next-line
  }, []);

  const handleButtonClick = () => {
    window.analytics.track("Checkout Step Viewed", { step: 'fitting_voucher_1' });

    window.gtag('event', 'Checkout Step Viewed', { step: 'fitting_voucher_1' });
    if (!loggedInUser) {
      setIsModalOpen(true)
    } else {
      window.analytics.identify(loggedInUser.get('id'), {
        firstName: loggedInUser.get('first_name'),
        lastName: loggedInUser.get('last_name'),
        email: loggedInUser.get('oidcData').get('email'),
      });
      window._cio.identify({
        id: loggedInUser.get('id'),
        email: loggedInUser.get('oidcData').get('email'),
        first_name: loggedInUser.get('first_name'),
        last_name: loggedInUser.get('last_name'),
      });
      setIsModalOpen(true);
      setErrorMessage('');
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const validateVoucher = async (voucherToRedeem) => {
    try {
      const actionResponse = await validateVoucherCode(voucherToRedeem);
      if (actionResponse) { 
        localStorage.setItem('voucher', voucherToRedeem);
        if(isAuthenticatedUser){
          history.push('/my-fitting');
        }else{
          localStorage.setItem('action', 'redeemVoucher');
          history.push('/login');
        }
        setErrorMessage('');
      } else {
        if(fittingCode === null || fittingCode === ''){
          throw new Error(strings.blankFittingCode);
        }else{
          throw new Error(strings.invalidFittingCode);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  if (fetchingLoggedInUser) {
    return <LoadingIndicator />;
  }

  const email = loggedInUser?.get('oidcData')?.get('email');
  const firstName = loggedInUser?.get('first_name');
  const lastName = loggedInUser?.get('last_name');
  const braintreeCustomerId = loggedInUser?.get('braintree_customer_id');
  const userId = loggedInUser?.get('id');

  const isAuthenticatedUser = email != null;

  return (
    <ScrollToTop>
      <div className="ballnamic--gift-page">
        <LandingPageHeader isLoggedIn={!!loggedInUser} loggedInUser={loggedInUser} />
        <div className="container gift-page">
          <div className="gift-voucher-text-wrapper">
            <h1>{strings.voucherHeading}</h1>
          </div>
          <div className="gift-inner">
            <p>{strings.fittingCode}</p>
            <div className="form-field" style={{ paddingBottom: '20px' }}>
              <input
                type="text"
                id="fittingCode"
                name="fittingCode"
                placeholder={strings.insertFittingCode}
                value={fittingCode}
                onChange={(e) => {
                  setFittingCode(e.target.value);
                  setErrorMessage('');
                }}
                style={{ borderRadius: '0', fontWeight: '500', backgroundColor: '#F5F6FA', border: '1.0px solid #b6bdc2' }}
              />
              {errorMessage && <div className="error-message" style={{ color: 'red', paddingTop: '8px' }}>{errorMessage}</div>}
            </div>
            <button className="button button--color--primary gift-button" onClick={() => validateVoucher(fittingCode)}>
              {strings.startMyFitting}
            </button>
          </div>
        </div>
        <div className="purchase">{strings.voucherText1}<Link onClick={handleButtonClick}>{strings.voucherText2}</Link>{strings.voucherText3}</div>
        <PurchaseForm 
          isOpened={isModalOpen} 
          onCancel={handleModalClose} 
          initialEmail={email} 
          initialFirstName={firstName}
          initialLastName={lastName}
          isAuthenticatedUser={isAuthenticatedUser} 
          braintreeCustomerId={braintreeCustomerId}
          userId={userId}
        />
      </div>
    </ScrollToTop>
  );
};

export default Purchase;
