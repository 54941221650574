import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import LocalizedStrings from 'react-localization';
import { updateUserProfile, getMyFittingCodesList, fetchMyResultsList, getSubscriptionsList } from '../user/user-actions';
import compose from 'lodash/flowRight';
import moment from 'moment';
import localstorage from 'store2';
import { Helmet } from 'react-helmet';
import BillingHistory from './BillingHistory';
import { Link } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    myProfile: 'My Profile',
    profile: 'Profile',
    fittingCodes: 'Fitting Codes',
    fittingResults: 'Fitting Results',
    subscriptions: 'Subscriptions',
    billingHistory: 'Billing History',
    edit: 'Edit',
    viewAll: 'View All',
    redeemed: 'REDEEMED',
    redeemNow: 'REDEEM NOW',
    viewResults: 'View Results',
    subscribed: 'Subscribed',
    noFittingCodes: 'Get ',
    noFittingResults: 'No fitting results available',
    noSubscriptions: 'No Subscriptions available',
    here: 'here',
  },
});
class MyAccount extends Component {
  componentDidMount() {
    const { dispatch, loggedInUser } = this.props;
    dispatch(getMyFittingCodesList({email: loggedInUser.getIn(['oidcData', 'email'])}));
    dispatch(getSubscriptionsList({email: loggedInUser.getIn(['oidcData', 'email'])}));
    dispatch(fetchMyResultsList());
  }

  renderProfile() {
    const { loggedInUser } = this.props;

    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.profile}</h2>
          <Link className="my-account-edit-button" to="/profile">{strings.edit}</Link>
        </div>
        <div className="my-account-profile-info">
          <div>{`${loggedInUser.get('first_name')} ${loggedInUser.get('last_name')}`}</div>
          <div>{loggedInUser.getIn(['oidcData', 'email'])}</div>
          <div>{loggedInUser.get('current_ball')}</div>
          <div>{loggedInUser.get('zip_code')}</div>
        </div>
      </div>
    );
  }

  renderFittingCodes() {
    const { myFittingCodesList } = this.props;
    
    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.fittingCodes}</h2>
          {myFittingCodesList.size > 0 && <Link to="/profile/my-fitting-codes" className="my-account-view-all-button">
            {strings.viewAll}
          </Link>}
        </div>
        <div className="my-account-codes-list">
          { myFittingCodesList.size === 0 ? <div>{strings.noFittingCodes}<Link to="/purchase" style={{ textDecoration: 'underline' }}>{strings.here}</Link></div> : myFittingCodesList.slice(0, 5).map(code => (
            <div key={code.get('code')} className="my-account-code-item">
              <span>{code.get('code').toUpperCase()}</span>
              <span className={`status ${code.get('status') === 'active' ? 'redeem-now' : 'redeemed'}`}>
                {code.get('status') === 'active' ? (
                  <a
                    href={'/purchase'}
                    rel="noopener noreferrer"
                    onClick={() => {
                      localStorage.setItem('action', 'redeemVoucher');
                      localStorage.setItem('voucher', code.get('code').toUpperCase());
                    }}
                  >
                    {strings.redeemNow}
                  </a>
                ) : (
                  strings.redeemed
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderFittingResults() {
    const { history, fittingResults } = this.props;
    const promoCode = JSON.parse(localstorage.get('promoCode'));

    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.fittingResults}</h2>
          {fittingResults.size > 0 && <button 
            onClick={() => history.push("/profile/my-results")} 
            className="my-account-view-all-button my-account-link-button"
          >
            {strings.viewAll}
          </button>}
        </div>
        <div className="my-account-results-list">
          {fittingResults.size === 0 ? (
            <div>{strings.noFittingResults}</div>
          ) : (
            fittingResults.map(result => (
              <div key={result.created_at} className="my-account-result-item">
                <span>{moment(result.get('created_at')).format('MM/DD/YYYY @ hh:mma')}</span>
                <button 
                onClick={() => window.open(promoCode?.code ? `${result.get('url')}?p=${promoCode?.code}` : result.get('url'), '_blank')} 
                className="view-results-button">{strings.viewResults}</button>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  renderSubscriptions() {
    const { history, mySubscriptionsList } = this.props;

    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.subscriptions}</h2>
          {mySubscriptionsList.size > 0 && <button 
            onClick={() => history.push("/profile/my-results")} 
            className="my-account-view-all-button my-account-link-button"
          >
            {strings.edit}
          </button>}
        </div>
        <div className="my-account-results-list">
          {mySubscriptionsList.size === 0 ? (
            <div>{strings.noSubscriptions}</div>
          ) : (
            mySubscriptionsList.map(result => (
              <div key={result.name} className="my-account-result-item">
                <span>{(result.get('name'))}</span>
                <span>{(result.get('status'))}</span>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  renderBillingHistory() {
    const { history, transactions } = this.props;
    
    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.billingHistory}</h2>
          {transactions?.get('total') > 0 && <button 
            onClick={() => history.push("/profile#billing")} 
            className="my-account-view-all-button my-account-link-button"
          >
            {strings.viewAll}
          </button>
          }
        </div>
        <BillingHistory/>
      </div>
    );
  }

  render() {
    return (
      <div className="my-account-page">
        <Helmet>
          <title>{`${strings.myProfile} - ${strings.title}`}</title>
        </Helmet>
        <h1>My Account</h1>
        <div className="my-account-sections-grid">
          {this.renderProfile()}
          {this.renderFittingCodes()}
          {this.renderFittingResults()}
          {this.renderBillingHistory()}
          {this.renderSubscriptions()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    userProfileUpdated: state.user.updateUserProfile.get('loaded'),
    myFittingCodesList: state.user.myFittingCodesList,
    transactions: state.payment.billingHistory,
    fittingResults: state.user.myResultsList,
    mySubscriptionsList: state.user.mySubscriptionsList,
  })),
  withFormik({
    mapPropsToValues: props => ({
      first_name: props.loggedInUser.get('first_name') || '',
      last_name: props.loggedInUser.get('last_name') || '',
    }),
    handleSubmit(values, { props }) {
      return props.dispatch(updateUserProfile(values));
    },
  })
)(MyAccount);
