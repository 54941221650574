import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { fetchLoggedInUser } from './auth/auth-actions';
import Admin from './admin/Admin';
import Questionnaire from './fitting/Questionnaire';
import FitterLayout from './fitting/FitterLayout';
import { logout } from './auth/auth-redirects';
import { withRouter } from 'react-router-dom';
import GetStarted from './fitting/Questionnaire/GetStarted';
import Profile from './user/Profile';
import NotFound from './NotFound';
import MyAccount from './my-account';

class AuthenticatedApp extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchLoggedInUser());
    
    if(this.props.loggedInUser){
      window.analytics.page("Signed In",{
        email: this.props.email
      });
      window.analytics.track("Signed In", {
        first_name: this.props.loggedInUser.get('first_name'),
        last_name: this.props.loggedInUser.get('last_name'),
        email: this.props.loggedInUser.get('oidcData').get('email')
      });
      window.gtag('event', 'Signed In', {
        first_name: this.props.loggedInUser.get('first_name'),
        last_name: this.props.loggedInUser.get('last_name'),
        email: this.props.loggedInUser.get('oidcData').get('email')
      });
      window.analytics.identify(this.props.loggedInUser.get('id'),{
        firstName: this.props.loggedInUser.get('first_name'),
        lastName: this.props.loggedInUser.get('last_name'),
        email: this.props.loggedInUser.get('oidcData').get('email'),
      });
      window._cio.identify({
        id: this.props.loggedInUser.get('id'),
        email: this.props.loggedInUser.get('oidcData').get('email'),
        first_name: this.props.loggedInUser.get('first_name'),
        last_name: this.props.loggedInUser.get('last_name'),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loadingError && this.props.loadingError) {
      logout();
    }
  }

  render() {
    const { history, loggedInUser } = this.props;

    if (!loggedInUser) {
      return null;
    }

    if (loggedInUser.get('deleted_at')) {
      history.replace('/no-access');
    }



    return (
      <div className="auth-app">
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route>
            <FitterLayout>
              <Switch>
                <Route exact path="/my-fitting/get-started" component={GetStarted} />
                <Route path="/my-fitting" component={Questionnaire} />
                <Route path="/my-account" component={MyAccount} />
                <Route path="/profile" component={Profile} />
                <Route path="*" component={NotFound} />
              </Switch>
            </FitterLayout>
          </Route>
        </Switch>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loadingUser: state.auth.fetchLoggedInUser.get('loading'),
    loadingError: state.auth.fetchLoggedInUser.get('error'),
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(AuthenticatedApp);
