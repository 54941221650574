import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import LandingPageHeader from './LandingPageHeader';
import Footer from '../common/components/Footer';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';
import { fetchLoggedInUser } from '../auth/auth-actions';
import { fetchPrices } from '../payment/payment-actions';

import LoadingIndicator from '../common/components/LoadingIndicator';
import ScrollToTop from '../common/components/ScrollToTop';
import Announcement from './Announcement';

import ComprehensiveFitting from './images/guesswork/ComprehensiveFitting.png';
import CheckIcon from './images/pricing/check.png';
import DataDriven from './images/guesswork/DataDriven.png';
import TourLevel from './images/guesswork/Tourlevel.png';
import VimeoEmbed from '../common/components/VimeoEmbed';
import ClubUpGolf from './images/review/ClubUpGolf.png';
import GolfDigest from './images/review/GolfDigest.png';
import GolfDotCom from './images/review/GolfDotCom.png';
import MyGolfSpy from './images/review/MyGolfSpy.png';
import Quotes from './images/quotes/quotes.png';
import Quotes2 from './images/quotes/quotes2.png';
import Callaway from './images/topmatches/callaway.png';
import Srixon from './images/topmatches/srixon.png';
import Bfour from './images/topmatches/bfour.png';

import ModerateSpin from './images/closer-look/ModerateSpin.png';
import DriverDetails from './images/closer-look/DriverDetails.png';
import Fuel from './images/closer-look/Fuel.png';
import SevenIron from './images/closer-look/SevenIron.png';


const strings = new LocalizedStrings({
  en: {
    login: 'Login',
    registration: 'Register',
    library: 'Library',
    startMyFitting: 'Start My Fitting',
    getStarted: 'Get Started',
    getFit: 'Buy Now',
    learnMore: 'Learn More',
    findBallTitle: 'Take the Guesswork Out of Finding the Perfect Ball for Your Game.',
    comprehensiveFitting: 'Comprehensive Fitting Experience',
    comprehensiveFittingDescription: 'You’ll answer a thorough set of questions about your swing information, launch conditions, and yardages. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.',

    tourLevelTechnology: 'Tour-Level Technology',
    tourLevelTechnologyDescription: 'Ballnamic is built upon a database of over 60 ball models, and it`s frequently being updated. In order to account for all playing conditions and launch angles, each ball undergoes hundreds of test shots with a state-of-the-art swing robot and a TrackMan Launch Monitor. The data is compiled and analyzed by PINGs team of data scientists.',

    dataDrivenResults: 'Data Driven Results',
    dataDrivenResultsDescription: 'Go beyond results that match your swing style, and see what other golf balls are best for you, depending on your course conditions. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.',

    reviewedAndTestedBy: 'REVIEWED AND TESTED BY',
    getTopMatches: 'Get Top Matches Within Minutes',
    getTopMatchesDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.',

    match: 'MATCH',
    idealStoppingPower: 'IDEAL STOPPING POWER',
    topDriverDistance: 'TOP DRIVER DISTANCE',
    idealWorkability: 'IDEAL WORKABILITY',
    idealStoppingPowerPercentage: '95.1%',
    topDriverDistancePercentage: '97.0%',
    idealWorkabilityPercentage: '94.9%',
    chromeSoftX: 'CHROME SOFT X',
    tourBX: 'TOUR B X',
    zStarXV: 'Z STAR XV',

    whatsIncluded: 'What’s Included',
    dataDrivenBallRecommendations: '5 Data-Driven Ball Recommendations',
    dataDrivenBallRecommendationsDescription: 'You’ll answer a thorough set of questions about  your swing information, launch conditions, and yardages.',

    performanceInsightsForEachBall: 'Performance Insight for Each Ball',
    performanceInsightsForEachBallDescription: 'Over 40 balls have been analyzed throughrigorous testing to help you choose between thetop five balls that match your game.',

    chooseAPackageOfFitting: 'Choose a package of 1, 3 or 5 Fittings',
    chooseAPackageOfFittingDescription: 'Go beyond results that match your swing style, and see what other golf balls are best for you, depending on your course conditions.',

    aCloserLookAtBallnamic: 'A Closer Look at Ballnamic',

    substantialTesting: 'Substantial Testing',
    substantialTestingDescription: 'Rigorous and unbiased testing evaluates and estimates relevant areas of ball performance, including how they perform in wet conditions and lies in the rough.',
    databaseAndFlightModels: 'Our database and flight models provide projections for full down range driver and irons trajectories, workability, wind stability, and spin generation for full-swing and greenside wedge performance.',

    tellUsAboutYourGame: 'Tell Us About Your Game',
    tellUsAboutYourGameDescription: 'You will be guided to think about balls with the intelligence and depth of a Tour player. Entering nuanced information about yourself and where you play, from trajectory information to feel preference around the greens, will drive the Ballnamic fitting algorithms.',

    swingAnalysis: 'Swing Analysis',
    swingAnalysisDescription: 'We\'ll run your inputs against our ball database that is powered by cutting-edge algorithms and thousands of test shots, which evaluate and estimate ball performance in various conditions.',

    actionableResults: 'Actionable Results',
    actionableResultsDescription: 'Receive four ball recommendations with detailed performance insights on workability, wind stability, and spin generation, as well as flight model projections for full down range driver and iron trajectories.',
    actionableResultsDescription2: 'You’ll have access to these results for ____.',

    ballnamicPricing: 'BALLNAMIC PRICING',
    findTheRightPackageForYou: 'Find the Right Package for You',

    fiveFittings: '5 Fittings',
    fitScoreThatIdentifiesHowWellABallMatchesYourGame: 'A fit score that identifies how well a ball matches your game',
    performanceMetricsForEachBall: 'Performance metrics for each ball',
    projectedFlightChartsToCompareBallPerformance: 'Projected flight charts to compare ball performance',
    fittingsForYouToUseOrShareWithOthers: ' for you to use or share with others',

    threeFittings: '3 Fittings',
    bestSeller: 'BEST SELLER',

    oneFitting: '1 Fitting',
    fourDataDrivenBallRecommendations: '4 Data-Driven Ball Recommendations',

    howItWorks: 'How Ballnamic Works',
    purchaseTheNumberOfFittingsYouWant: 'Purchase the number of fittings you want',
    registerForAnAccountAndEnterYourFittingCode: 'Register for an account and enter your fitting code',
    completeYourFittingAndUnlockGameChangingInsightsForYourBall: 'Complete your fitting and unlock game-changing insights for your ball',

    iFoundThisToolVeryHelpfulInNarrowingDownWhatGolfBallsIWillTestAndGivingMeConfidenceThatIMUsingTheRightOne: 'I FOUND THIS TOOL VERY HELPFUL IN NARROWING DOWN WHAT GOLF BALLS I WILL TEST AND GIVING ME CONFIDENCE THAT I\'M USING THE RIGHT ONE.',
    independentGolfReviews: 'Independent Golf Reviews',


    poweredBy: {
      heading: 'Powered by Tour-Level Technology',
      subText:
        "Ballnamic is built upon a database of over 60 ball models, and it's frequently being updated. In order to account for all playing conditions and launch angles, each ball undergoes hundreds of test shots with a state-of-the-art swing robot and a TrackMan Launch Monitor. The data is compiled and analyzed by PING's team of data scientists.",
    },
    customContact: 'Custom fitter inquiries can be submitted to {0}',
    copyright:
      '{0} Copyright 2020 PING Inc. All rights reserved. Ballnamic is a trademark of Karsten Manufacturing Corporation in the U.S. and other countries. All third-party trademarks are the property of their respective owners, and use of such trademarks does not imply any affiliation with or endorsement by them.',
  },
});



class LandingPage extends Component {
  componentDidMount() {
    window.analytics.page('Landing Page');
    const { dispatch, token } = this.props;
    if (token) {
      dispatch(fetchLoggedInUser());
    }
    dispatch(fetchPrices());

    window.addEventListener('load', this.priceScroll);
    window.addEventListener('resize', this.priceScroll);
  }

  priceScroll() {
    const priceOptions = document.getElementById('price-options');
    if (priceOptions) {
      priceOptions.scrollLeft = (980 - window.outerWidth) / 2;
    }

  }

  render() {
    const { loggedInUser, fetchingLoggedInUser, prices, fetchingPrices } = this.props;

    if (fetchingLoggedInUser || fetchingPrices || !prices) {
      return <LoadingIndicator />;
    }

    return (
      <ScrollToTop>
        <div className="ballnamic--landing-page">
          <script src="https://player.vimeo.com/api/player.js"></script>

          <Announcement />

          <LandingPageHeader isLoggedIn={!!loggedInUser} loggedInUser={loggedInUser} />

          <div id="hero">
            <h1>The Most Comprehensive Online Ball Fitting Tool in Golf.</h1>

            <VimeoEmbed id='928771863' />

            <div className='button-startlifting-learnmore'>
              <Link className="button button--color--primary" to={"/purchase"} onClick={
                () => {
                  localStorage.setItem('selectedFitting', 'premium');
                }
              }>
                {strings.getStarted}
              </Link>
            </div>
          </div>

          <div className="main-content">
            <div id="find-ball">
              <h2>{strings.findBallTitle}</h2>
              <div className="balls-list">
                <div className="ball-details">
                  <img src={ComprehensiveFitting} alt="Data Driven Ball Recommendations" />
                  <h3>{strings.comprehensiveFitting}</h3>
                  <p>{strings.comprehensiveFittingDescription}</p>
                </div>

                <div className="ball-details">
                  <img src={TourLevel} alt="Performance Insights" />
                  <h3>{strings.tourLevelTechnology}</h3>
                  <p>{strings.tourLevelTechnologyDescription}</p>
                </div>

                <div className="ball-details">
                  <img src={DataDriven} alt="5 Fittings" />
                  <h3>{strings.dataDrivenResults}</h3>
                  <p>{strings.dataDrivenResultsDescription}</p>
                </div>
              </div>
            </div>

            <div id='review-tested'>
              <div>
                <h2>{strings.reviewedAndTestedBy}</h2>
                <div className='review-list'>
                  <img src={MyGolfSpy} alt="Club Up Golf" className='vertical' />
                  <img src={GolfDotCom} alt="Golf Digest" />
                  <img src={GolfDigest} alt="Golf Dot Com" />
                  <img src={ClubUpGolf} alt="My Golf Spy" />
                </div>
              </div>
            </div>
            <div id="top-matches">
              <h2>{strings.getTopMatches}</h2>
              <p>{strings.getTopMatchesDescription}</p>

              <div className="top-matches-list">
                <div className="top-match">
                  <p>{strings.idealWorkability}</p>
                  <h4>{strings.idealWorkabilityPercentage}</h4>
                  <h3>{strings.match}</h3>
                  <img src={Callaway} alt="Data Driven Ball Recommendations" />
                  <h2>{strings.chromeSoftX}</h2>
                </div>

                <div className="top-match">
                  <p>{strings.topDriverDistance}</p>
                  <h4>{strings.topDriverDistancePercentage}</h4>
                  <h3>{strings.match}</h3>
                  <img src={Srixon} alt="Performance Insights" />
                  <h2>{strings.zStarXV}</h2>
                </div>

                <div className="top-match">
                  <p>{strings.idealStoppingPower}</p>
                  <h4>{strings.idealStoppingPowerPercentage}</h4>
                  <h3>{strings.match}</h3>
                  <img src={Bfour} alt="5 Fittings" />
                  <h2>{strings.tourBX}</h2>
                </div>
              </div>
            </div>

            <div id="whats-included">
              <h2>{strings.whatsIncluded}</h2>

              <div className="features">
                <div className="feature">
                  <img src={TourLevel} alt="Data Driven Ball Recommendations" />
                  <h3>{strings.dataDrivenBallRecommendations}</h3>
                  <p>{strings.dataDrivenBallRecommendationsDescription}</p>
                </div>

                <div className="feature">
                  <img src={TourLevel} alt="Performance Insights" />
                  <h3>{strings.performanceInsightsForEachBall}</h3>
                  <p>{strings.performanceInsightsForEachBallDescription}</p>
                </div>

                <div className="feature">
                  <img src={TourLevel} alt="5 Fittings" />
                  <h3>{strings.chooseAPackageOfFitting}</h3>
                  <p>{strings.chooseAPackageOfFittingDescription}</p>
                </div>
              </div>
            </div>
            <div id="closer-look">
              <h2>{strings.aCloserLookAtBallnamic}</h2>
              <div className="videos-list">
                <div className="video-item">
                  <div className='item-text'>
                    <h3>{strings.substantialTesting}</h3>
                    <p>{strings.substantialTestingDescription}</p>
                    <p>{strings.databaseAndFlightModels}</p>
                  </div>
                  <div className='item-video'>
                    <img src={Fuel} alt='Fuel' />
                  </div>
                </div>
                <div className="video-item">
                  <div className='item-text'>
                    <h3>{strings.tellUsAboutYourGame}</h3>
                    <p>{strings.tellUsAboutYourGameDescription}</p>
                  </div>
                  <div className='item-video'>
                    <img src={DriverDetails} alt='Driver Details' />
                  </div>
                </div>
                <div className="video-item">
                  <div className='item-text'>
                    <h3>{strings.swingAnalysis}</h3>
                    <p>{strings.swingAnalysisDescription}</p>
                  </div>
                  <div className='item-video'>
                    <img src={SevenIron} alt='Seven Iron' />
                  </div>
                </div>
                <div className="video-item">
                  <div className='item-text'>
                    <h3>{strings.actionableResults}</h3>
                    <p>{strings.actionableResultsDescription}</p>
                    <p>{strings.actionableResultsDescription2}</p>
                  </div>
                  <div className='item-video'>
                    <img src={ModerateSpin} alt='Moderate Spin' />
                  </div>
                </div>
              </div>
            </div>

            <div id="pricing">
              <div className="lines">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h2>{strings.ballnamicPricing}</h2>

              <h3>{strings.findTheRightPackageForYou}</h3>

              <div className="pricing-options" id="price-options">
                <div className="pricing-option">
                  <p><div className="fake-button">5 Fittings</div></p>
                  <h4>${(prices?.premium) / 100}</h4>
                  <ul>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.dataDrivenBallRecommendations}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.fitScoreThatIdentifiesHowWellABallMatchesYourGame}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.performanceMetricsForEachBall}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.projectedFlightChartsToCompareBallPerformance}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      <span className="lif">{strings.fiveFittings}</span>{strings.fittingsForYouToUseOrShareWithOthers}</li>
                  </ul>
                  <Link className="button button--color--primary  button--theme--primary" to={"/purchase"} onClick={
                    () => {
                      localStorage.setItem('selectedFitting', 'premium');
                    }
                  }>
                    {strings.getFit}
                  </Link>
                </div>

                <div className="pricing-option best-seller">
                  <p>
                    <div className="fake-button">{strings.threeFittings} <span className="best-seller-label">({strings.bestSeller})</span></div>
                  </p>
                  <h4>${(prices?.standard) / 100}</h4>
                  <ul>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.dataDrivenBallRecommendations}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.fitScoreThatIdentifiesHowWellABallMatchesYourGame}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.performanceMetricsForEachBall}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.projectedFlightChartsToCompareBallPerformance}</li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      <span className="lif">{strings.threeFittings}</span> {strings.fittingsForYouToUseOrShareWithOthers}</li>
                  </ul>
                  <Link className="button button--color--primary  button--theme--primary" to={"/purchase"} onClick={
                    () => {
                      localStorage.setItem('selectedFitting', 'standard');
                    }
                  }>
                    {strings.getFit}
                  </Link>
                </div>

                <div className="pricing-option">
                  <p><div className="fake-button">{strings.oneFitting}</div></p>
                  <h4>${(prices?.basic) / 100}</h4>
                  <ul>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.fourDataDrivenBallRecommendations}
                    </li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.fitScoreThatIdentifiesHowWellABallMatchesYourGame}
                    </li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.performanceMetricsForEachBall}
                    </li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      {strings.projectedFlightChartsToCompareBallPerformance}
                    </li>
                    <li>
                      <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                      <span className="lif">{strings.oneFitting}</span> {strings.fittingsForYouToUseOrShareWithOthers}</li>
                  </ul>
                  <Link className="button button--color--primary  button--theme--primary" to={"/purchase"}
                    onClick={
                      () => {
                        localStorage.setItem('selectedFitting', 'basic');
                      }
                    }>
                    {strings.getFit}
                  </Link>
                </div>
              </div>
            </div>

            <div id="how-it-works">
              <div>
                <h2> {strings.howItWorks}</h2>
                <div className="steps">
                  <h3>1</h3>
                  <p>{strings.purchaseTheNumberOfFittingsYouWant}</p>
                </div>

                <div className="steps">
                  <h3>2</h3>
                  <p>{strings.registerForAnAccountAndEnterYourFittingCode}</p>
                </div>

                <div className="steps">
                  <h3>3</h3>
                  <p>{strings.completeYourFittingAndUnlockGameChangingInsightsForYourBall}</p>
                </div>

                <div className="button-startlifting">
                  <Link className="button button--color--primary button--rounded" to={"/purchase"} onClick={
                    () => {
                      localStorage.setItem('selectedFitting', 'premium');
                    }
                  }>
                    {strings.startMyFitting}
                  </Link>
                </div>
              </div>
            </div>

            <div id='quotes'>
              <div className='quotes-container'>
                <img src={Quotes} alt="Quotes" className="quote-mark" />

                <div className='quote'>
                  <h3>{strings.iFoundThisToolVeryHelpfulInNarrowingDownWhatGolfBallsIWillTestAndGivingMeConfidenceThatIMUsingTheRightOne}</h3>
                  <h4>— {strings.independentGolfReviews}</h4>
                </div>

                <img src={Quotes2} alt="Quotes" className="quote-mark" />
              </div>
            </div>
            <button 
              className="scroll-to-top" 
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              ↑
            </button>
          </div>

          <Footer />
        </div>
      </ScrollToTop>
    );
  }
}

export default compose(
  connect(state => ({
    token: state.auth.token,
    loggedInUser: state.auth.loggedInUser,
    fetchingLoggedInUser: state.auth.fetchLoggedInUser.get('loading'),
    prices: state.payment.prices,
    fetchingPrices: state.payment.fetchPrices.get('loading'),
  }))
)(LandingPage);
