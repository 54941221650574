import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Helmet } from 'react-helmet';
import { getMyFittingCodesList } from '../../user-actions';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import TableHistoryIcon from '../../../common/components/icons/TableHistory';
import ShareForm from './ShareForm';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    myFittingCodes: 'My Fitting Codes',
    code: 'FITTING CODES',
    status: 'STATUS',
    share: 'Share',
    shareAgain: 'Share Again',
    redeemNow: 'Redeem Now',
    noResults: 'No fitting codes',
    purchaseFits: 'Purchase Fittings',
    purchaseMoreFits: 'Purchase More Fittings'
  },
});

const MyFittingCodes = () => {
  const dispatch = useDispatch();
  const myFittingCodesList = useSelector(state => state.user.myFittingCodesList);
  const loaded = useSelector(state => state.user.getMyFittingCodesList.get('loaded'));
  const loggedInUser = useSelector(state => state.auth.loggedInUser);

  const [isModalOpen, setIsModalOpen] = useState(localStorage.getItem('action') === 'shareVoucher');
  const [selectedCode, setSelectedCode] = useState(localStorage.getItem('voucher'));

  useEffect(() => {
    dispatch(getMyFittingCodesList({email: loggedInUser.getIn(['oidcData', 'email'])}));
    localStorage.removeItem('action');
    localStorage.removeItem('voucher');
  }, [dispatch, loggedInUser]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const renderMyFittingCodesTable = () => {
    if (myFittingCodesList.size === 0) {
      return (
        <div className="results-history--zero-content">
          <h3>{strings.noResults}</h3>
          <TableHistoryIcon fill="#576975" size={48} />
        </div>
      );
    }

    return (
      <table className="codes-list results-history-table">
        <thead>
          <tr>
            <th>{strings.code}</th>
            <th>{strings.status}</th>
          </tr>
        </thead>
        <tbody>
          {myFittingCodesList.map(result => (
            <tr key={result.get('code')}>
              <td>{result.get('code').toUpperCase()}</td>
              <td>
                {result.get('status') === 'active' ? (
                  <a
                    href={'/purchase'}
                    rel="noopener noreferrer"
                    onClick={() => {
                      localStorage.setItem('action', 'redeemVoucher');
                      localStorage.setItem('voucher', result.get('code').toUpperCase());
                    }}
                  >
                    {strings.redeemNow}
                  </a>
                ) : (
                  result.get('status').toUpperCase()
                )}
              </td>
              <td>
                {result.get('status') === 'active' && (
                  <Link                   
                    onClick={() => {
                      setSelectedCode(result.get('code').toUpperCase());
                      setIsModalOpen(true);
                    }}
                  >
                    {result.get('shared') ? strings.shareAgain : strings.share}
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="page--my-profile">
      <Helmet>
        <title>{`${strings.myFittingCodes} - ${strings.title} `}</title>
      </Helmet>
      <div className="container small">
        <h1>{strings.myFittingCodes}</h1>
        {!loaded ? <LoadingIndicator /> : renderMyFittingCodesTable()}
      </div>
      <div className='purchase-more-fitting'>
        <NavLink className="button button--color--primary" to="/purchase" >
          {myFittingCodesList.size === 0 ? strings.purchaseFits : strings.purchaseMoreFits}
        </NavLink>
      </div>
      <ShareForm isOpened={isModalOpen} onCancel={handleModalClose} selectedCode={selectedCode} />
    </div>  
  );
};

export default MyFittingCodes;