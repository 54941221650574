import React, { useState } from 'react';
import Modal from '../../../common/components/Modal';
import ValidationError from '../../../common/forms/ValidationError';
import LocalizedStrings from 'react-localization';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { shareVoucher } from '../../../user/user-actions';

const strings = new LocalizedStrings({
  en: {
    PaymentInformation: 'Payment Information',
    shareTitle: 'Share or gift your fitting code with friend’s and family.',
    Email: 'RECIPIENT’S Email *',
    ConfirmEmail: 'RECIPIENT’S Confirm Email *',
    buttonNext: 'Next',
    buttonSend: 'Send',
    AnErrorOccurredPaymentForm: 'An error occurred with the payment form.',
    InvalidEmailFormat: 'Invalid email format',
    EmailsMustMatch: 'Emails must match',
    InvalidConfirmEmailFormat: 'Invalid Confirm email format',
    EmailIsRequired: 'Email is required',
    ConfirmEmailIsRequired: 'Confirm Email is required',
    price: 'Amount',
    salesTax: 'Sales Tax',
    updating: 'Updating',
    invoice: 'Invoice',
    total: 'Total',
    PleaseEnterEmailAddress: 'Please enter the email address that you would like the voucher sent to:',
    EmailThatWillReceiveVoucher: 'Email that will receive the voucher:',
  },
});

const ShareForm = ({ isOpened, onCancel, initialEmail, selectedCode }) => {
  const [titleModal, setTitleModal] = useState(strings.shareTitle);
  const [email, setEmail] = useState(initialEmail);
  const [confirmEmail, setConfirmEmail] = useState(initialEmail);
  const [emailError, setEmailError] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState('');
  const [allowNext, setAllowNext] = useState(initialEmail != null ? true : false);
  const dispatch = useDispatch();
  
  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setEmailError(strings.InvalidEmailFormat);
      setAllowNext(false);
    } else if (confirmEmail && event.target.value !== confirmEmail) {
      setEmailError(strings.EmailsMustMatch);
      setAllowNext(false);
    } else {
      setEmailError('');
      setConfirmEmailError('');
      setAllowNext(true);
    }
  };

  const handleConfirmEmailChange = event => {
    setConfirmEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setConfirmEmailError(strings.InvalidConfirmEmailFormat);
      setAllowNext(false);
    } else if (email !== event.target.value) {
      setConfirmEmailError(strings.EmailsMustMatch);
      setAllowNext(false);
    } else {
      setEmailError('');
      setConfirmEmailError('');
      setAllowNext(true);
    }
  };

  const handleFormSubmit = async () => {
    if (!email) {
      setEmailError(strings.EmailIsRequired);
    }
    if (!confirmEmail) {
      setConfirmEmailError(strings.ConfirmEmailIsRequired);
    }

    if (email && confirmEmail && allowNext) {
      try {
        const result = await dispatch(shareVoucher({ code: selectedCode, email: email }));
        if (result.response.status === 200) {
          handleClose();
          toast('Voucher shared successfully!', { 
            type: 'success', 
            position: toast.POSITION.TOP_CENTER 
          });
        } else {
          toast('Error sharing voucher', { 
            type: 'error', 
            position: toast.POSITION.BOTTOM_CENTER 
          });
        }
      } catch (error) {
        console.error('Error sending gift voucher email:', error);
        toast('Error sharing voucher', { 
          type: 'error', 
          position: toast.POSITION.BOTTOM_CENTER 
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handleClose = () => {
    setTitleModal(strings.shareTitle);
    setEmail('');
    setConfirmEmail('');
    setEmailError('');
    setConfirmEmailError('');
    setAllowNext(false);
    onCancel();
  };
  
  return (
    <Modal
      className="voucher-modal"
      isOpened={isOpened}
      onClose={handleClose}
      title={titleModal}
      shouldCloseOnOutsideClick={false}
    >
      
        <div className="email-form">
          <h3 style={{ fontSize: '24px', fontWeight: '800', color: 'blue' }}>{selectedCode}</h3>
          <div className="input-container">
            <label className="input-label" htmlFor="email-id">
              {strings.Email}
            </label>
            <input id="email-id" name="email" type="email" value={email} onChange={handleEmailChange} />
            {emailError && <ValidationError>{emailError}</ValidationError>}
          </div>
          <div className="input-container">
            <label className="input-label" htmlFor="confirmEmail-id">
              {strings.ConfirmEmail}
            </label>
            <input
              id="confirmEmail-id"
              name="confirmEmail"
              type="email"
              value={confirmEmail}
              onChange={handleConfirmEmailChange}
            />
            {confirmEmailError && <ValidationError>{confirmEmailError}</ValidationError>}
          </div>
          <div className="buttonNext-container">
            <button
              onClick={handleFormSubmit}
              className="button button--color--primary"
              aria-label="Next"
              disabled={!allowNext}
            >
              {strings.buttonSend}
            </button>
          </div>
        </div>
     
    </Modal>
  );
};

export default ShareForm;
