import React, { Component } from 'react';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import LocalizedStrings from 'react-localization';
import * as Yup from 'yup';
import RegisterFieldWithLabelAndValidation from '../common/forms/RegisterFieldWithLabelAndValidation';
import { register, fetchLoggedInUser } from './auth-actions';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';
import ValidationError from '../common/forms/ValidationError';
import LandingPageHeader from '../landing-page/LandingPageHeader';
import { Link } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'Registration',
    register: 'Register to Start Your Fitting',
    voucher: 'FITTING CODE',
    voucherPlaceHolder: 'ENTER YOUR FITTING CODE',
    alreadyRegistered: 'Already have an account? {0}',
    login: 'Log in',
    createAccount: 'Start My Fitting',
    firstName: 'FIRST NAME',
    lastName: 'LAST NAME',
    email: 'EMAIL',
    password: 'PASSWORD',
    fieldIsRequired: 'Field is required',
    fieldMustBeAValidEmailAddress: 'Field must be a valid email address',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    mustAgreeTerms: 'Agreeing to the Terms of Use and Privacy Policy is required',
    firstNamePlaceholder: 'ENTER YOUR FIRST NAME',
    lastNamePlaceholder: 'ENTER YOUR LAST NAME',
    emailPlaceholder: 'ENTER YOUR EMAIL',
    passwordPlaceholder: 'ENTER YOUR PASSWORD',
    usOnlyDisclaimer: 'Ballnamic is currently available in the U.S. only',
    agreeToTermsAndPolicy: 'I agree to the {0} and have read the {1}',
  },
});

class Registration extends Component {
  componentDidMount() {
    window.analytics.page("Registration");
  }
  componentDidUpdate(prevProps) {

    const { registered, loggedInUser, history, dispatch } = this.props;
    if (!prevProps.registered && registered) {
      dispatch(fetchLoggedInUser());
    }

    if (!prevProps.loggedInUser && loggedInUser) {
      if (localStorage.getItem('action') === 'redeemVoucher') {
        history.push('/purchase');
      } else if (localStorage.getItem('action') === 'shareVoucher' || localStorage.getItem('action') === 'listVouchers') {
        history.push('/profile/my-fitting-codes');
      } else {
        history.push('/my-account');
      }
    }
  }

  renderError() {
    const { error } = this.props;

    if (!error) {
      return null;
    }

    return <ValidationError>{error.get('message')}</ValidationError>;
  }

  render() {
    const { submitting, loading, loggedInUser } = this.props;
    
    return (
      <div className="registration--page">
        <LandingPageHeader isLoggedIn={!!loggedInUser} loggedInUser={loggedInUser} />
        <div className="container">
          <div className="register-form__wrapper">
            <div className="register-form--header">
              <h1>{strings.register}</h1>
            </div>

            {this.renderError()}
            <Form>
              <div className="register-form--name">
                <RegisterFieldWithLabelAndValidation
                  className="first-name"
                  label={strings.firstName}
                  fieldProps={{
                    name: 'first_name',
                    type: 'text',
                    required: true,
                    placeholder: strings.firstNamePlaceholder,
                  }}
                />
                <RegisterFieldWithLabelAndValidation
                  className="last-name"
                  label={strings.lastName}
                  fieldProps={{
                    name: 'last_name',
                    type: 'text',
                    required: true,
                    placeholder: strings.lastNamePlaceholder,
                  }}
                />
              </div>
              <RegisterFieldWithLabelAndValidation
                label={strings.email}
                fieldProps={{
                  name: 'email',
                  type: 'email',
                  required: true,
                  placeholder: strings.emailPlaceholder,
                }}
              />
              <RegisterFieldWithLabelAndValidation
                label={strings.password}
                fieldProps={{
                  name: 'password',
                  type: 'password',
                  required: true,
                  placeholder: strings.passwordPlaceholder,
                }}
              />
              <RegisterFieldWithLabelAndValidation
                label={strings.voucher}
                fieldProps={{
                  name: 'voucher',
                  type: 'text',
                  placeholder: strings.voucherPlaceHolder,
                }}
              />
              <label className="checkbox">
                <Field type="checkbox" name="agreedToTermsAndPolicy" />
                <span>
                  {strings.formatString(
                    strings.agreeToTermsAndPolicy,
                    <Link target="_blank" to="/terms-of-use">
                      {strings.termsOfUse}
                    </Link>,
                    <Link target="_blank" to="/privacy">
                      {strings.privacyPolicy}
                    </Link>
                  )}
                </span>
                <ErrorMessage name="agreedToTermsAndPolicy" component={ValidationError} />
              </label>

              <div className="registration--page--button__wrapper">
                <button className="button button--color--primary" type="submit" disabled={submitting || loading}>
                  {strings.createAccount}
                </button>
                <div>
                  {strings.formatString(
                    strings.alreadyRegistered,
                    <Link to="/login" aria-label={strings.alreadyRegistered}>
                      {strings.login}
                    </Link>
                  )}
                </div>
              </div>
            </Form>
            <p className="us-only-disclaimer-text">{strings.usOnlyDisclaimer}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loading: state.auth.register.get('loading') || state.auth.fetchLoggedInUser.get('loading'),
    registered: state.auth.register.get('loaded'),
    error: state.auth.register.get('error'),
    loggedInUser: state.auth.loggedInUser,
  })),
  withFormik({
    mapPropsToValues: props => ({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      voucher: localStorage.getItem('voucher') || '',
      agreedToTermsAndPolicy: false,
    }),
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(strings.fieldIsRequired),
      last_name: Yup.string().required(strings.fieldIsRequired),
      email: Yup.string()
        .email(strings.fieldMustBeAValidEmailAddress)
        .required(strings.fieldIsRequired),
      password: Yup.string()
        .min(8)
        .required(strings.fieldIsRequired),
      agreedToTermsAndPolicy: Yup.boolean()
        .equals([true], strings.mustAgreeTerms)
        .required(),
    }),
    handleSubmit: (values, { props }) => {

      if(values.voucher){
        localStorage.setItem('action', 'redeemVoucher');
        localStorage.setItem('voucher', values.voucher.toUpperCase());
      }

      window.analytics.track("Account Created", {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email
      });
      window.gtag('event', 'Account Created', {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email
      });
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
      };

      return props.dispatch(register(payload));
    },
  })
)(Registration);
