import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Logo from '../common/components/logo/Logo';
import MenuIcon from '../common/components/icons/Menu';
import ClearIcon from '../common/components/icons/Clear';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    login: 'Login',
    myAccount: 'My Account',
    myProfile: 'Profile',
    library: 'Library',
    getFit: 'Get Fit',
    landingPage: 'Landing Page',
    toggleSideBar: 'Toggle Side Bar',
    faq: 'FAQ',
    profile: 'Profile',
    fittingCode: 'Fitting Code',
    fittingResults: 'Fitting Results',
    billingHistory: 'Billing History',
    Subscriptions: 'Subscriptions'
  },
});

const activeStyle = {
  borderBottom: '4px solid #5057FF',
};

class LandingPageHeader extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    history: PropTypes.object.isRequired,
  };

  state = {
    showKebabMenu: false,
    showMobileKebabMenu: false,
    showSideBar: false,
    showDropdown: false,
  };

  toggleSidebar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  };

  handleMouseEnter = () => {
    this.setState({ showDropdown: true });
  };

  handleMouseLeave = () => {
    this.setState({ showDropdown: false });
  };

  renderMobileNav() {
    const { isLoggedIn } = this.props;

    return (
      <div className="mobile-nav">
        <div className="mobile-nav__flex-full">
          <button type="button" onClick={this.toggleSidebar} aria-label={strings.toggleSideBar}>
            <MenuIcon fill="#616A70" />
          </button>
        </div>
        <div className="mobile-nav__logo">
          <Logo fill="#5057FF" width={111.67} height={32} />
        </div>
        <div className="mobile-nav__flex-full">
          {isLoggedIn && (
            <Link className="button button--color--primary button--theme--outline login-button" to="/my-fitting">
              {strings.startMyFitting}
            </Link>
          )}
        </div>
      </div>
    );
  }

  renderSideBar() {
    const { showSideBar } = this.state;

    const sidebarClasses = {
      sidebar: true,
      'sidebar--open': showSideBar,
    };

    const sidebarHeaderClasses = {
      'sidebar--header': true,
      'sidebar--header--open': showSideBar,
    };

    const mobileOverlayClasses = {
      'mobile-overlay': true,
      'mobile-overlay--visible': showSideBar,
    };
    const sidebarMenuClasses = {
      'sidebar-menu': true,
      'sidebar-menu--visible': showSideBar,
    };

    return (
      <Fragment>
        <div className={classNames(sidebarClasses)}>
          <div className={classNames(sidebarHeaderClasses)}>
            <button type="button" onClick={this.toggleSidebar} aria-label={strings.toggleSideBar}>
              <ClearIcon fill="#616A70" />
            </button>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="button button-gift-mobile user-nav__links--link" to="/purchase" activeStyle={activeStyle}>
              {strings.getFit}
            </NavLink>
          </div>
          {this.props.isLoggedIn && (
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink
              className="user-nav__links--link" to="/my-account" activeStyle={activeStyle}>
              {strings.myAccount}
            </NavLink>
          </div>
          )}
           {this.props.isLoggedIn && (
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink
              className="user-nav__links--link" to="/profile" activeStyle={activeStyle}>
              {strings.myProfile}
            </NavLink>
          </div>
          
          )}
          {!this.props.isLoggedIn && (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink className="user-nav__links--link" to="/login" activeStyle={activeStyle}>
                {strings.login}
              </NavLink>
            </div>
          )}
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="user-nav__links--link" to="/library" activeStyle={activeStyle}>
              {strings.library}
            </NavLink>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="user-nav__links--link" to="/faq" activeStyle={activeStyle}>
              {strings.faq}
            </NavLink>
          </div>

          {!this.props.isLoggedIn && (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink className="user-nav__links--link" to="/registration" activeStyle={activeStyle}>
                {strings.registration}
              </NavLink>
            </div>
          )}
        </div>
        <div className={classNames(mobileOverlayClasses)} onClick={this.toggleSidebar} />
      </Fragment>
    );
  }

  render() {
    const { isLoggedIn, history } = this.props;
    const { showDropdown } = this.state;

    return (
      <div className="landing-page-header">
        {this.renderMobileNav()}
        {this.renderSideBar()}
        <div className="container">
          <header className="header">
            <div className="header__logo-wrapper">
              <Link to="/" className="logo" aria-label={strings.landingPage}>
                <Logo width={172} height={48} />
              </Link>
            </div>
            <nav className="nav" role="navigation" aria-label="Navigation menu">
              <div className="nav-links">
                <div>
                  <Link className="button button--color--primary button--theme--outline" to="/library">
                    {strings.library}
                  </Link>
                </div>
                <div>
                  <Link className="button button--color--primary button--theme--outline" to="/faq">
                    {strings.faq}
                  </Link>
                </div>
                <div style={{ width: 1, height: 48, backgroundColor: 'rgba(80, 87, 255, 0.5)' }} />
                <div className='gift-button-wrapper'>
                  {isLoggedIn ? (
                    <div className="dropdown-container"
                      onMouseEnter={this.handleMouseEnter}
                      onMouseLeave={this.handleMouseLeave}>
                      <button
                        className={classNames(
                          "button button--color--primary button--theme--outline",
                          { "button--active": showDropdown }
                        )}
                        onClick={() => history.push('/my-account')}
                      >
                        {strings.myAccount}
                      </button>
                      {showDropdown && (
                        <div className="dropdown-menu">
                          <Link to="/profile" className="dropdown-item">{strings.profile}</Link>
                          <Link to="/profile/my-results" className="dropdown-item">{strings.fittingResults}</Link>
                          <Link to="/profile/my-fitting-codes" className="dropdown-item">{strings.fittingCode}</Link>
                          <Link to="/profile#billing" className="dropdown-item">{strings.billingHistory}</Link>
                          {/* <Link to="/profile" className="dropdown-item">{strings.Subscriptions}</Link> */}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link className="button button--color--primary button--theme--outline" to="/login">
                      {strings.login}
                    </Link>
                  )}
                </div>
                <div>
                  <Link
                    className="button button--color--primary button--theme--primary"
                    to={"/purchase"}
                    onClick={
                      () => {
                        if (!isLoggedIn) {
                          localStorage.setItem('purchaseBallFitting', 'true');
                        }
                        localStorage.removeItem('selectedFitting');
                      }
                    }
                  >
                    {strings.getFit}
                  </Link>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>
    );
  }
}

export default withRouter(LandingPageHeader);
