import client from '../client';

import {
  INVITE_FITTER,
  FETCH_FITTERS,
  DELETE_FITTER,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_PROFILE,
  UPDATE_FITTER,
  FETCH_MY_RESULTS_LIST,
  CLEAR_FITTER_CACHE,
  UPDATE_USER_BRAND_SETTINGS,
  GET_MY_FITTING_CODES_LIST,
  SHARE_VOUCHER,
  GET_SUBSCRIPTIONS_LIST,
} from './user-action-constants';

export function inviteFitter(email) {
  return { type: INVITE_FITTER, promise: client.post('fitters', { email }) };
}

export function fetchFitters(page, pageSize, filter) {
  let queryParams;
  if (filter !== null && typeof filter !== 'undefined') {
    queryParams = { page, pageSize, filter };
  } else {
    queryParams = { page, pageSize };
  }
  return { type: FETCH_FITTERS, promise: client.get('fitters', queryParams) };
}

export function deleteFitter(userId) {
  return { type: DELETE_FITTER, promise: client.delete(`fitters/${userId}`) };
}

export function updateUserSettings(settings) {
  return {
    type: UPDATE_USER_SETTINGS,
    promise: client.patch(`user/settings`, settings),
  };
}

export function updateUserBrandSettings(settings) {
  return {
    type: UPDATE_USER_BRAND_SETTINGS,
    promise: client.post(`brands/settings`, settings),
  };
}

export function updateUserProfile(values) {
  return {
    type: UPDATE_USER_PROFILE,
    promise: client.patch(`user`, values),
  };
}

export function updateFitter(id, values) {
  return {
    type: UPDATE_FITTER,
    promise: client.patch(`fitters/${id}`, values),
    id,
    values,
  };
}

export function fetchMyResultsList() {
  return {
    type: FETCH_MY_RESULTS_LIST,
    promise: client.get('fitting/my-results-list'),
  };
}

export function clearFitterCache() {
  return {
    type: CLEAR_FITTER_CACHE,
    promise: client.post('fitting/clearcache'),
  };
}

export function getMyFittingCodesList(payload) {
  return {
    type: GET_MY_FITTING_CODES_LIST,
    promise: client.post('v2/payment/vouchers', payload),
  };
}

export function getSubscriptionsList(payload) {
  return {
    type: GET_SUBSCRIPTIONS_LIST,
    promise: client.post('user/subscriptions', payload),
  };
}


export function shareVoucher(payload) {
  return {
    type: SHARE_VOUCHER,
    promise: client.post('v2/share-voucher', payload),
  };
}